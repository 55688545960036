



































































































































































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Watch, Ref, Prop } from "vue-property-decorator";
import {
  AttachmentHostType,
  DataDictionaryDto,
  ProductParamCreateOrUpdateDto,
  ProductParamInputType,
  ProductSkuCreateOrUpdateDto,
  ProductSkuShortDto,
  ProductSpecParamDto,
} from "@/api/appService";

export interface Specification<T> {
  category: string;
  name: string;
  id?: string;
  data?: T;
  children?: Array<Specification<T>>;
  isLeaf: boolean;
}

interface SpecificationItem {
  unitId?: number;
  imagePath: string;
  id?: number;
  model?: number;
}

@Component({
  name: "specification",
})
export default class specification extends Vue {
  specificationsList = [] as ProductSpecParamDto[];
  selectedSpecificationIds = [] as any[];
  inputList = [] as any[];
  selectAll = false;
  parameterValue = "";
  manualInputs = {} as any;
  tableItems = [] as any[];
  treeItems = [] as any[];
  index = 0;
  testTableArr = [] as any[]; // table数据
  spanKey = [] as any[]; // 需要合并的列,每个元素为表格的prop值（键值）
  tableTitle = {} as any; // table表头显示
  tableData = {} as any;
  tableTitleKey = [] as any[]; // 所有的列的prop值（键值）
  spanMethod = [] as any[]; // 表格向下合并的方式
  divArr = [] as any[]; // 分片数据，用于将数据分片，保证不会
  divIndex = [0]; // 用于记录分片的位置
  imageUrl = "";
  public onProgress = false;
  public uploadPercent = 0;
  unitList = [] as DataDictionaryDto[];
  skus = [] as ProductSkuShortDto[];

  @Prop({ required: true })
  form: any;

  @Prop({ required: false })
  skuList?: Array<ProductSkuShortDto>;

  @Watch("skuList", { deep: true, immediate: true })
  skuListChange(newVal?: ProductSkuShortDto[]) {
    this.skus = this.skuList ?? [];
  }

  async created() {
    await this.fetchSpecificationsList();
    await this.fetchUnitList();
    if (this.form.id !== 0) {
      this.changeSelect(
        Array.from(
          new Set(
            this.form.specParamMappingList?.map((item: any) => item.specParamId)
          )
        )
      );
      this.form.specParamMappingList?.map((item: any) => {
        if (
          item.fkSpecParam?.inputType === ProductParamInputType.DropDownOptions
        ) {
          let index = this.inputList
            ?.map((i) => i.id)
            .indexOf(item.specParamId);
          if (index === -1) {
            this.inputList.push({
              id: item.specParamId,
              fullName: item.fkSpecParam?.fullName,
              name: item.fkSpecParam?.name,
              nameFr: item.fkSpecParam?.nameFr,
              inputType: "DropDownOptions",
              parameterFullValues: JSON.parse(item.fkSpecParam?.inputList).map(
                (newItem: any) =>
                  newItem.parameterValue +
                  (newItem.parameterValueFr
                    ? `(${newItem.parameterValueFr})`
                    : "")
              ),
              parameterValues: JSON.parse(item.fkSpecParam?.inputList).map(
                (newItem: any) => newItem.parameterValue
              ),
              parameterValueFrs: JSON.parse(item.fkSpecParam?.inputList).map(
                (newItem: any) => newItem.parameterValueFr
              ),
              selectedFullValues: [`${item.value}(${item.valueFr})`],
              selectedValues: [item.value],
              selectedValueFrs: [item.valueFr],
              disabledFullValues: [`${item.value}(${item.valueFr})`],
            });
          } else {
            this.inputList[index].selectedFullValues.push(
              `${item.value}(${item.valueFr})`
            );
            this.inputList[index].selectedValues.push(item.value);
            this.inputList[index].selectedValueFrs.push(item.valueFr);
            this.inputList[index].selectedFullValues = Array.from(
              new Set(this.inputList[index].selectedFullValues)
            );
            this.inputList[index].selectedValues = Array.from(
              new Set(this.inputList[index].selectedValues)
            );
            this.inputList[index].selectedValueFrs = Array.from(
              new Set(this.inputList[index].selectedValueFrs)
            );

            this.inputList[index].disabledFullValues.push(
              `${item.value}(${item.valueFr})`
            );
            this.inputList[index].disabledFullValues = Array.from(
              new Set(this.inputList[index].disabledFullValues)
            );
          }
        } else {
          let index = this.inputList
            ?.map((i) => i.id)
            .indexOf(item.specParamId);
          if (index === -1) {
            this.inputList.push({
              id: item.specParamId,
              name: item.fkSpecParam?.name,
              inputType: "ManualInput",
              selectedFullValues: [item.value],
              selectedValues: [item.value],
              selectedValueFrs: [item.value],
            });
          } else {
            this.inputList[index].selectedFullValues.push(
              `${item.value}(${item.valueFr})`
            );
            this.inputList[index].selectedValues.push(item.value);
            this.inputList[index].selectedValueFrs.push(item.valueFr);
            this.inputList[index].selectedFullValues = Array.from(
              new Set(this.inputList[index].selectedFullValues)
            );
            this.inputList[index].selectedValues = Array.from(
              new Set(this.inputList[index].selectedValues)
            );
            this.inputList[index].selectedValueFrs = Array.from(
              new Set(this.inputList[index].selectedValueFrs)
            );
          }
          this.$set(
            this.manualInputs,
            index === -1 ? this.inputList.length - 1 : index,
            {
              inputValue: "",
              inputValueFr: "",
              inputVisible: false,
            }
          );
        }
      });
      this.tableItemsChange();
    }
  }

  getSkuListData() {
    console.log("get sku list data--form skuList: ", this.form.skuList);
    console.log("get sku list data--skuList: ", this.skus);

    let specParamMappingValueStrList = this.skus?.map(
      (sku: ProductSkuShortDto) => {
        return JSON.stringify(
          sku.specParamMappingList?.map((mapping: any) => mapping.value).sort()
        );
      }
    );
    debugger;
    for (let i in this.form.skuList) {
      let valueListJson = JSON.stringify(
        this.form.skuList[i].specParamList
          ?.map((item: any) => item.value)
          .sort()
      );
      if (
        specParamMappingValueStrList &&
        specParamMappingValueStrList.length > 0
      ) {
        let index = specParamMappingValueStrList.indexOf(valueListJson);
        if (index > -1) {
          this.form.skuList[i].unitId = this.skus![index].unitId;
          this.form.skuList[i].imagePath = this.skus![index].imagePath;
          this.form.skuList[i].id = this.skus![index].id;
          this.form.skuList[i].model = this.skus![index].model;
          this.form.skuList[i].isActive = this.skus![index].isActive;
          this.testTableArr[Number(i)].unitId = this.skus![index].unitId;
          this.testTableArr[Number(i)].imagePath = this.skus![index].imagePath;
          this.testTableArr[Number(i)].id = this.skus![index].id;
          this.testTableArr[Number(i)].model = this.skus![index].model;
          this.testTableArr[Number(i)].isActive = this.skus![index].isActive;
        } else {
          this.form.skuList[i].isActive = false;
          this.testTableArr[Number(i)].isActive = false;
        }
      }
    }
    // for (let i in this.skuList) {
    //   for (let j = 0; j < this.form.skuList.length; j++) {
    //     if (
    //       JSON.stringify(
    //         this.form.skuList[i].specParamList
    //           ?.map((item: any) => item.value)
    //           .sort()
    //       ) ==
    //       JSON.stringify(
    //         this.skuList[j].specParamMappingList
    //           ?.map((item: any) => item.value)
    //           ?.sort()
    //       )
    //     ) {
    //       this.form.skuList[i].unitId = this.skuList[j].unitId;
    //       this.form.skuList[i].imagePath = this.skuList[j].imagePath;
    //       this.form.skuList[i].id = this.skuList[j].id;
    //       this.form.skuList[i].model = this.skuList[j].model;
    //       this.testTableArr[Number(i)].unitId = this.skuList[j].unitId;
    //       this.testTableArr[Number(i)].imagePath = this.skuList[j].imagePath;
    //       this.testTableArr[Number(i)].id = this.skuList[j].id;
    //       this.testTableArr[Number(i)].model = this.skuList[j].model;
    //       //
    //     }
    //   }
    // }
  }

  async fetchSpecificationsList() {
    let _this = this as any;
    await api.productSpecParam.getAll({ pageSize: 65535 }).then((res) => {
      _this.specificationsList = res.items ?? [];
    });
  }

  async fetchUnitList() {
    let _this = this as any;
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "ProductSkuUnit" })
      .then((res) => {
        _this.unitList = res.items ?? [];
      });
  }

  beforeUpload(file: any) {
    console.log("beforeUpload:", file);
    const isJPG =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/gif";
    const isLt1M = file.size / 1024 / 1024 < 1;

    if (!isJPG) {
      this.$message.error(
        (this as any).$l.getLanguageText("commodityBrand.tips.type") as string
      );
    }
    if (!isLt1M) {
      this.$message.error(
        (this as any).$l.getLanguageText("commodityBrand.tips.size") as string
      );
    }
    return isJPG && isLt1M;
  }

  public get uploadData(): any {
    return { hostType: AttachmentHostType.Product };
  }

  public handleProgress(e: any): void {
    this.onProgress = true;
    this.uploadPercent = e.percent;
    console.log("handleProgress", e);
  }

  get headers() {
    return {
      "Abp-OrganizationUnitId": (this as any).$store.getters["user/ouId"] || "",
      "Abp-TenantId": (this as any).$store.getters["app/tenantId"],
      Authorization: `Bearer ${(this as any).$store.getters["user/token"]}`,
      ".AspNetCore.Culture": "c=zh-Hans|uic=zh-Hans",
    };
  }

  handleRemove(file: any, fileList: any, index: any) {
    console.log(file, fileList);
    this.testTableArr[index].imagePath = "";
    this.handleSkusChange(this.testTableArr[index]);
  }

  handleSuccess(index: any, res: any, file: any) {
    this.onProgress = false;
    this.uploadPercent = 100;
    this.testTableArr[index].imagePath = res.result.url as string;
    // this.form.imagePath = res.result.url
    // this.getSkuList();
    this.handleSkusChange(this.testTableArr[index]);
  }

  changeSelect(value: any) {
    if (this.selectAll) {
      this.selectAll = false;
      if (value?.indexOf("selectAll") > -1) {
        this.selectedSpecificationIds = value.filter(
          (p: any) => p != "selectAll"
        );
      } else {
        this.selectedSpecificationIds = [];
      }
    } else {
      //   是否点击了‘全选’选项
      if (value?.indexOf("selectAll") > -1) {
        // 有‘全选’选项，则将‘全部’和其他值放置一块
        const optionsValue: any = [];
        this.specificationsList.forEach((item: any) => {
          console.log(item);
          optionsValue.push(item.id);
        });
        // this.selectedSpecificationIds = ["selectAll", ...optionsValue];
        this.selectedSpecificationIds = [...optionsValue];
        this.selectAll = true;
      } else {
        // 若是勾选选择的长度和提供的选项长度是一样的，则是 ‘全选’
        if (value?.length === this.specificationsList.length) {
          const optionsValue: any = [];
          this.specificationsList.forEach((item: any) => {
            optionsValue.push(item.id);
          });
          // this.selectedSpecificationIds = ["selectAll", ...optionsValue];
          this.selectedSpecificationIds = [...optionsValue];
          this.selectAll = true;
        } else {
          //   都是单选
          this.selectedSpecificationIds = value;
        }
      }
    }
    // 真实的勾选值
    const realSelect =
      this.selectedSpecificationIds?.filter(
        (item: any) => item != "selectAll"
      ) ?? [];
    console.log("realSelect", realSelect);
    console.log("selectedSpecificationIds", this.selectedSpecificationIds);
    this.init(realSelect);
  }

  init(realSelect: any) {
    let inputList = [] as any[];
    inputList = inputList.concat(this.inputList);
    this.inputList = [];
    for (let i of realSelect) {
      for (let j in this.specificationsList) {
        let index = inputList.map((item) => item.id).indexOf(i);
        if (
          i === this.specificationsList[j].id &&
          this.specificationsList[j].inputType === "DropDownOptions"
        ) {
          let inputArr = JSON.parse(this.specificationsList[j].inputList!);
          this.inputList.push({
            id: i,
            fullName: this.specificationsList[j].fullName,
            name: this.specificationsList[j].name,
            nameFr: this.specificationsList[j].nameFr,
            inputType: "DropDownOptions",
            parameterFullValues: inputArr.map(
              (item: any) =>
                item.parameterValue +
                (item.parameterValueFr ? `(${item.parameterValueFr})` : "")
            ),
            parameterValues: inputArr.map((item: any) => item.parameterValue),
            parameterValueFrs: inputArr.map(
              (item: any) => item.parameterValueFr
            ),
            selectedFullValues: inputList[index]?.selectedFullValues
              ? inputList[index]?.selectedFullValues
              : [],
            selectedValues: inputList[index]?.selectedValues
              ? inputList[index]?.selectedValues
              : [],
            selectedValueFrs: inputList[index]?.selectedValueFrs
              ? inputList[index]?.selectedValueFrs
              : [],
            disabledFullValues: inputList[index]?.disabledFullValues
              ? inputList[index]?.disabledFullValues
              : [],
          });
        } else if (
          i === this.specificationsList[j].id &&
          this.specificationsList[j].inputType === "ManualInput"
        ) {
          this.inputList.push({
            id: i,
            fullName: this.specificationsList[j].fullName,
            name: this.specificationsList[j].name,
            nameFr: this.specificationsList[j].nameFr,
            inputType: "ManualInput",
            selectedFullValues: inputList[index]?.selectedFullValues
              ? inputList[index]?.selectedFullValues
              : [],
            selectedValues: inputList[index]?.selectedValues
              ? inputList[index]?.selectedValues
              : [],
            selectedValueFrs: inputList[index]?.selectedValueFrs
              ? inputList[index]?.selectedValueFrs
              : [],
          });
          this.$set(this.manualInputs, this.inputList.length - 1, {
            inputValue: "",
            inputValueFr: "",
            inputVisible: false,
          });
        }
      }
    }
    console.log("inputList: ", this.inputList);
    console.log("manualInputs: ", this.manualInputs);
    this.tableItemsChange();
  }

  handleClose(item: any, tag: any) {
    item.selectedFullValues.splice(item.selectedFullValues.indexOf(tag), 1);
    item.selectedValues.splice(item.selectedValues.indexOf(tag), 1);
    item.selectedValueFrs.splice(item.selectedValueFrs.indexOf(tag), 1);
    this.tableItemsChange();
  }

  showInput(index: number) {
    this.manualInputs[index].inputVisible = true;
    let _this = this as any;
    this.$nextTick(() => {
      _this.$refs.saveTagInput.$refs?.input.focus();
      _this.$refs.saveTagInputFr.$refs?.input.focus();
    });
  }

  handleInputConfirm(item: any, index: number) {
    if (
      this.manualInputs[index].inputValue &&
      this.manualInputs[index].inputValueFr
    ) {
      let inputValue =
        this.manualInputs[index].inputValue +
        `(${this.manualInputs[index].inputValueFr})`;
      item.selectedFullValues.push(inputValue);
      item.selectedValues.push(this.manualInputs[index].inputValue);
      item.selectedValueFrs.push(this.manualInputs[index].inputValueFr);
      this.manualInputs[index].inputVisible = false;
      this.tableItemsChange();
    }
  }

  handleCheckboxChange(item: any) {
    if (item.selectedFullValues && item.selectedFullValues.length > 0) {
      let selectedValues = [] as string[];
      let selectedValueFrs = [] as string[];
      item.selectedFullValues.forEach((fullValue: string) => {
        let index = item.parameterFullValues.indexOf(fullValue);
        let value = item.parameterValues[index];
        let valueFr = item.parameterValueFrs[index];
        selectedValues.push(value);
        selectedValueFrs.push(valueFr);
      });
      item.selectedValues = selectedValues;
      item.selectedValueFrs = selectedValueFrs;
    } else {
      item.selectedValues = [];
      item.selectedValueFrs = [];
    }
    this.tableItemsChange();
  }

  tableItemsChange() {
    this.tableItems = [] as Specification<SpecificationItem>[];
    let selectedValuesList = [];

    console.log("inputList: ", this.inputList);
    let inputList = this.inputList.filter(
      (item: any) => item.selectedFullValues.length > 0
    );
    selectedValuesList = inputList.map((item) => {
      return {
        id: item.id!,
        fullName: item.fullName,
        name: item.name,
        nameFr: item.nameFr,
        selectedFullValues: item.selectedFullValues,
        selectedValues: item.selectedValues,
        selectedValueFrs: item.selectedValueFrs,
      };
    });
    for (let i in selectedValuesList) {
      this.$set(
        this.tableTitle,
        selectedValuesList[i].fullName,
        selectedValuesList[i]
      );
    }
    if (selectedValuesList.length > 0) {
      this.tableItems = this.createTree(0, selectedValuesList);
      let newData = [] as any[];
      let treeItems = [] as any[];
      for (let i = 0; i < selectedValuesList.length; i++) {
        let newlist = [] as any[];
        for (
          let index = 0;
          index < selectedValuesList[i].selectedValues.length;
          index++
        ) {
          let selectedValue = selectedValuesList[i];
          newlist.push({
            fullValue: selectedValue.selectedFullValues[index],
            value: selectedValue.selectedValues[index],
            valueFr: selectedValue.selectedValueFrs[index],
            isActive: true,
          });
        }
        newData.push(newlist);
      }
      this.treeItems = this.descartes(newData);
    } else {
      this.tableItems = [];
      this.treeItems = [];
    }
    this.tableTitleKey = selectedValuesList.map((item) => item.fullName);
    this.spanKey = selectedValuesList.map((item) => item.fullName);
    this.spanKey.splice(this.spanKey.length - 1, 1);
    console.log("tableItems: ", this.tableItems);
    console.log("tableTitleKey: ", this.tableTitleKey);
    console.log("treeItems: ", this.treeItems);
    this.tableData = {
      key: this.tableTitleKey,
      value: this.treeItems,
    };
    this.getInit();
  }

  createTree(j: number, data: any) {
    let tree = [] as Specification<SpecificationItem>[];
    if (j < data.length - 1) {
      for (let i in data[j].selectedFullValues) {
        tree = tree.concat([
          {
            category: data[j].fullName,
            name: data[j].selectedFullValues[i],
            id: data[j]?.id + "-" + ++this.index,
            children: this.createTree(j + 1, data),
            isLeaf: false,
          },
        ]);
      }
    } else if (j === data.length - 1) {
      for (let i in data[j].selectedFullValues) {
        tree = tree.concat([
          {
            category: data[j].fullName,
            name: data[j].selectedFullValues[i],
            id: data[j]?.id + "-" + ++this.index,
            data: {
              unitId: undefined,
              imagePath: "",
              id: undefined,
              model: undefined,
            },
            isLeaf: true,
          },
        ]);
      }
    }
    return tree;
  }

  descartes(array: any) {
    if (array.length < 2) return array[0] || [];
    else {
      return array.reduce(function (col: any, set: any) {
        let res = [] as any[];
        col.forEach(function (c: any) {
          set.forEach(function (s: any) {
            let t = [] as any[];
            t = t.concat(Array.isArray(c) ? c : [c]);
            t.push(s);
            res.push(t);
          });
        });
        return res;
      });
    }
  }

  getSkuList() {
    let _this = this as any;
    _this.form.skuList = [];
    console.log("testTableArr: ", this.testTableArr);
    console.log("tableTitleKey: ", this.tableTitleKey);
    for (let i in _this.testTableArr) {
      let specParamList = [] as any[];
      for (let j in _this.inputList) {
        Object.keys(_this.testTableArr[i]).forEach((value) => {
          if (_this.inputList[j].fullName === value) {
            specParamList.push({
              value: _this.testTableArr[i][value].value,
              valueFr: _this.testTableArr[i][value].valueFr,
              id: _this.inputList[j].id,
            });
          }
        });
      }
      _this.form.skuList.push({
        specParamList: specParamList,
        imagePath: _this.testTableArr[i].imagePath,
        unitId: _this.testTableArr[i].unitId,
        id: _this.testTableArr[i].id,
        model: _this.testTableArr[i].model,
        isActive: _this.testTableArr[i].isActive,
      });
    }
  }

  handleSkusChange(row: any) {
    let specParamMappingValueStrList = this.skus?.map(
      (sku: ProductSkuShortDto) => {
        return JSON.stringify(
          sku.specParamMappingList!.map((mapping: any) => mapping.value).sort()
        );
      }
    );
    let specParamList = [] as any[];
    for (let j in this.inputList) {
      Object.keys(row).forEach((value) => {
        if (this.inputList[j].fullName === value) {
          specParamList.push({
            value: row[value].value,
            valueFr: row[value].valueFr,
            id: this.inputList[j].id,
          });
        }
      });
    }
    console.log("specParamMappingValueStrList: ", specParamMappingValueStrList);
    let valueListJson = JSON.stringify(
      specParamList?.map((item: any) => item.value).sort()
    );
    console.log("valueListJson: ", valueListJson);
    let index = specParamMappingValueStrList.indexOf(valueListJson);
    if (index > -1) {
      this.skus[index].imagePath = row.imagePath;
      this.skus[index].unitId = row.unitId;
      this.skus[index].model = row.model;
    } else {
      this.skus.push({
        specParamMappingList: specParamList,
        imagePath: row.imagePath,
        unitId: row.unitId,
        model: row.model,
        isActive: row.isActive,
      });
    }

    this.getSkuList();
  }

  // treeConvertToArr(j:number,tree:any) {
  //   let arr = [] as any
  //   // if(!field) field = "children";
  //   // for(let key in tree) {
  //   //   let obj = tree[key] as any[];
  //   //   let clone = JSON.parse(JSON.stringify(obj))
  //   //   delete clone[field];
  //   //   result.push(clone);
  //   //   if(obj[field]) {
  //   //     let tmp = this.treeConvertToArr(obj[field],field);
  //   //     result = result.concat(tmp);
  //   //   }
  //   // }
  //   if(tree[j]?.children && tree[j]?.children.length > 0){
  //     arr = arr.concat(tree[j].name)
  //     tree = tree[j].children
  //     arr = arr.concat(this.treeConvertToArr(j,tree))
  //   } else {
  //     arr = arr.concat(tree[j].name)
  //   }
  //   return arr;
  // }

  getInit() {
    let _this = this as any;
    setTimeout(() => {
      // mock异步
      let response = this.tableData;
      _this.testTableArr = this.initTableData({
        key: response.key,
        value: response.value,
      });
      _this.getSkuList();
      this.normalizeData(this.testTableArr, this.spanKey);
      _this.getSkuListData();
      // if (_this.form.id !== 0) {
      //   _this.getSkuListData();
      // }
    }, 200);
  }

  /**
   * 将response{key:[],value:[]}  转换成可用于表格的数据
   * @param response
   * @returns {Promise}
   */
  initTableData(response: any) {
    let initArr = [] as any[];
    for (const indexValue in response.value) {
      let initObj = {} as any;
      for (const indexKey in response.key) {
        if (response.value[indexValue] instanceof Array) {
          initObj[response.key[indexKey]] =
            response.value[indexValue][indexKey];
        } else {
          initObj[response.key[indexKey]] = response.value[indexValue];
        }
      }
      initObj.imagePath = "";
      initObj.id = undefined;
      initObj.unitId = undefined;
      initObj.model = undefined;
      initObj.index = Math.floor((Math.random() * 9 + 1) * 1000000);
      initObj.isActive = true;
      initArr.push(initObj);
    }
    return initArr;
  }

  /**
   * 合并表格
   */
  arraySpanMethod({ rowIndex, columnIndex }: any) {
    if (this.spanKey.length) {
      for (let keyIndex = 0; keyIndex < this.spanKey.length; keyIndex++) {
        // 找到指定需要合并的列，应用合并规则
        if (this.spanKey[keyIndex] === this.tableTitleKey[columnIndex]) {
          const _row = this.spanMethod[keyIndex]
            ? this.spanMethod[keyIndex][rowIndex]
            : 0;
          const _col = _row > 0 ? 1 : 0;
          return {
            rowspan: _row,
            colspan: _col,
          };
        }
      }
    } else {
      return {
        rowspan: 1,
        colspan: 1,
      };
    }
  }

  /**
   * data为传入的表格数据，结构为[{},{}]
   * spanKey为需要合并操作的列  结构为[]
   */
  normalizeData(data: any, spanKey: any) {
    this.spanMethod = [];
    this.divIndex = [0];
    // 有需要合并的字段才做处理
    if (spanKey.length) {
      for (let keyIndex = 0; keyIndex < spanKey.length; keyIndex++) {
        const key = spanKey[keyIndex];
        this.sliceData();
        this.spanMethod.push(this.spanKeyMethod(key));
      }
    }
  }

  /**
   * key 为指定列的键值
   * contactArr 指定的列下 行的合并方式   []
   * contactDot 单元格合并展示位
   */
  spanKeyMethod(key: any) {
    let divContactArr = [] as any[]; // 记录当前列的向下合并方式
    // 计算每一片单独的行扩展方式
    let divIndexTemp = [...this.divIndex];
    // 重点： 记录每次循环的上一次分片位置，目的是让当前位置index插入在分片中   eg：[0,6,20]
    for (const itemIndex in this.divArr) {
      let divArrItem = this.divArr[itemIndex];
      let contactArr = [] as any[];
      let contactDot = 0;
      divArrItem.map((item: any, index: number) => {
        if (index == 0) {
          contactArr.push(1);
          contactDot = 0;
        } else {
          // 不是第一行的情况下比较和上一行的值
          if (item[key].fullValue === divArrItem[index - 1][key].fullValue) {
            // 值相同则放入0，第一个相同的合并方式数组加一
            contactArr[contactDot] += 1;
            contactArr.push(0);
          } else {
            contactArr.push(1);
            contactDot = index;
            // 记录分片的位置
            this.divIndex.push(index + divIndexTemp[itemIndex]);
            // 重点：插入分片中    eg：第一块分片位置index在0,6中插入   [0,2,3,5,6,20]  第二块分片位置index在6,20中插入[0,2,3,5,6,8,11,15,20]
            this.divIndex.sort((a, b) => {
              return a - b;
            }); // 因为是push进去  所以需要排序
            this.divIndex = [...new Set(this.divIndex)]; // 去重
          }
        }
      });
      divContactArr = divContactArr.concat(contactArr);
    }
    return divContactArr;
  }

  /**
   * 将数据分片，防止数据向下合并的时候跨级
   */
  sliceData() {
    this.divArr = [];
    for (let index = 0; index < this.divIndex.length; index++) {
      if (index == this.divIndex.length - 1) {
        // 如果最后一个则截取到数据最末尾
        this.divArr.push(this.testTableArr.slice(this.divIndex[index]));
      } else {
        // 截取两个分片位置之间的数据
        this.divArr.push(
          this.testTableArr.slice(
            this.divIndex[index],
            this.divIndex[index + 1]
          )
        );
      }
    }
  }

  activeRow(index: number) {
    this.testTableArr[index].isActive = true;
    this.handleSkusChange(this.testTableArr[index]);
  }

  disableRow(index: number) {
    this.testTableArr[index].isActive = false;
    this.handleSkusChange(this.testTableArr[index]);
    // this.treeItems.splice(index, 1);
    // this.tableData = {
    //   key: this.tableTitleKey,
    //   value: this.treeItems,
    // };
    // this.getInit();
  }
}
