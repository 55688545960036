











































































































































































































































































































































































import api from "@/api/index"; //ABP API接口
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import { ElForm } from "element-ui/types/form";
import {
  AttachmentHostType,
  ProductBasicParamDto,
  ProductCreateOrUpdateDto,
  ProductParamInputType,
  ProductSkuCreateOrUpdateDto,
  ProductSkuShortDto,
  WarehouseSelfLiftinPointDto,
} from "@/api/appService";
import classification from "@/views/commodity/commodity/components/classification.vue";
import specification from "@/views/commodity/commodity/components/sepcification.vue";
import Ueditor from "@/components/Ueditor.vue";
import multipleUploadFile from "@/components/MultipleUploadFile.vue";
import SimpleVideoUpload from "@/components/SimpleVideoUpload.vue";
import AttachmentsView from "@/components/AttachmentsView.vue";

@Component({
  name: "CommodityEdit",
  components: {
    classification,
    specification,
    Ueditor,
    multipleUploadFile,
    SimpleVideoUpload,
    AttachmentsView,
  },
})
export default class CommodityEdit extends Vue {
  @Ref() readonly dataForm!: ElForm;
  btnDisabled = false;

  dataId = 0;
  defaultData: ProductCreateOrUpdateDto = {
    id: 0,
    savedAttachments: [],
  };
  form: any = { ...this.defaultData };

  skuList = [] as ProductSkuShortDto[];

  basicParamsList = [] as ProductBasicParamDto[];
  selectedBasicParamIds = [] as any[];
  inputList = [] as any[];
  selectAll = false;
  parameterValue = "";

  manualInputs = {} as any;

  public onProgress = false;
  public uploadPercent = 0;
  imgArr = [] as any[];
  instructionsPath = [] as any[];

  warehouseSelfLiftinPointList = [] as WarehouseSelfLiftinPointDto[];

  roleRule = {
    categoryId: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "commodity.selectCategoryId"
        ) as string,
        trigger: "blur",
      },
    ],
    name: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "commodity.enterCommodityName"
        ) as string,
        trigger: "blur",
      },
    ],
    nameFr: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "commodity.enterCommodityNameFr"
        ) as string,
        trigger: "blur",
      },
    ],
    // basicParamList: [
    //   {
    //     required: true,
    //     message: "请选择商品规格",
    //     trigger: "blur",
    //   }
    // ]
    skuList: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "commodity.skuList"
        ) as string,
        trigger: "blur",
      },
    ],
    description: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "commodity.enterDescription"
        ) as string,
        trigger: "blur",
      },
    ],
    descriptionFr: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "commodity.enterDescriptionFr"
        ) as string,
        trigger: "blur",
      },
    ],
    savedAttachments: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "commodity.savedAttachments"
        ) as string,
        trigger: "blur",
      },
    ],
  };

  @Watch("form.categoryId")
  onValueChange(newVal: number, oldVal: number) {
    if (newVal !== oldVal) {
      //
    }
  }

  @Watch("form.instructionsPath")
  onChange(newVal: any) {
    this.form.instructionsPath = newVal;
    this.instructionsPath = [];
    this.instructionsPath.push(newVal);
  }

  instructionsPathChange(url: any) {
    this.form.instructionsPath = url;
  }

  get productFiles() {
    return AttachmentHostType.Product;
  }

  public get uploadData(): any {
    return { hostType: AttachmentHostType.Product };
  }

  public handleProgress(e: any): void {
    this.onProgress = true;
    this.uploadPercent = e.percent;
    console.log("handleProgress", e);
  }

  get headers() {
    return {
      "Abp-OrganizationUnitId": (this as any).$store.getters["user/ouId"] || "",
      "Abp-TenantId": (this as any).$store.getters["app/tenantId"],
      Authorization: `Bearer ${(this as any).$store.getters["user/token"]}`,
      ".AspNetCore.Culture": "c=zh-Hans|uic=zh-Hans",
    };
  }

  handleRemove(file: any, fileList: any) {
    console.log(file, fileList);
    // this.imageUrl = "";
  }

  handleSuccess(res: any, file: any) {
    this.onProgress = false;
    this.uploadPercent = 100;
    this.form.savedAttachments.push(res.result.attachmentId);
    this.form.mainImageId = this.form.savedAttachments[0];
    this.imgArr.push(res.result.url);
    // this.imageUrl = res.result.url as string
    // this.form.imagePath = res.result.url
  }

  setAsMasterGraph(index: any) {
    this.form.mainImageId = this.form.savedAttachments[index];
    //创建临时数组
    let temporaryArray = [] as any[];
    temporaryArray.push(this.form.savedAttachments[index]);
    this.form.savedAttachments.splice(index, 1);
    this.form.savedAttachments = temporaryArray.concat(
      this.form.savedAttachments
    );
    temporaryArray = [] as any[];
    temporaryArray.push(this.imgArr[index]);
    this.imgArr.splice(index, 1);
    this.imgArr = temporaryArray.concat(this.imgArr);
  }

  deleteImage(index: number) {
    this.form.savedAttachments.splice(index, 1);
    this.imgArr.splice(index, 1);
  }

  async created() {
    if (this.$route.params.id) {
      this.dataId = Number((this as any).$route.params.id);
    }
    if (this.$route.query.categoryId) {
      this.form.categoryId = Number((this as any).$route.query.categoryId);
    }
    if (this.dataId > 0) {
      await api.product.get({ id: this.dataId }).then((res) => {
        this.form = { ...this.form, ...res };
        this.skuList = res.skuList ?? [];
        // this.imgArr.push(res.fkMainImage?.url)
        // this.form.savedAttachments.push(res.fkMainImage?.id)
        api.attachmentService
          .getAttachments({
            hostId: String(this.dataId),
            hostType: AttachmentHostType.Product,
          })
          .then((result) => {
            this.imgArr = result.map((item) => item.url);
            this.form.savedAttachments = result.map((item) => item.id);
            let index = this.form.savedAttachments.indexOf(res.mainImageId);
            this.setAsMasterGraph(index);
          });
        this.changeSelect(
          res.basicParamMappingList?.map((item) => item.basicParamId)
        );
        res.basicParamMappingList?.map((item) => {
          if (
            item.fkBasicParam?.inputType ===
            ProductParamInputType.DropDownOptions
          ) {
            let input = {
              id: item.basicParamId,
              fullName: item.fkBasicParam?.fullName,
              name: item.fkBasicParam?.name,
              nameFr: item.fkBasicParam?.nameFr,
              inputType: "DropDownOptions",
              parameterFullValues: JSON.parse(
                item!.fkBasicParam!.inputList!
              ).map(
                (newItem: any) =>
                  newItem.parameterValue +
                  (newItem.parameterValueFr
                    ? `(${newItem.parameterValueFr})`
                    : "")
              ),
              parameterValues: JSON.parse(item!.fkBasicParam!.inputList!).map(
                (newItem: any) => newItem.parameterValue
              ),
              parameterValueFrs: JSON.parse(item!.fkBasicParam!.inputList!).map(
                (newItem: any) => newItem.parameterValueFr
              ),
              selectedFullValues: [`${item.value}(${item.valueFr})`],
              selectedValues: item.value ? item.value?.split(",") : [],
              selectedValueFrs: item.valueFr ? item.valueFr?.split(",") : [],
            };
            input.selectedFullValues = input.selectedValues.map(
              (value: string, index: number) =>
                value +
                (input.selectedValueFrs[index]
                  ? `(${input.selectedValueFrs[index]})`
                  : "")
            );
            this.inputList.push(input);
          } else {
            this.inputList.push({
              id: item.basicParamId,
              fullName: item.fkBasicParam?.fullName,
              name: item.fkBasicParam?.name,
              nameFr: item.fkBasicParam?.nameFr,
              inputType: "ManualInput",
              selectedFullValues: [`${item.value}(${item.valueFr})`],
              selectedValues: [item.value],
              selectedValueFrs: [item.valueFr],
            });
            this.$set(this.manualInputs, this.inputList.length - 1, {
              inputValue: item.value,
              inputValueFr: item.valueFr,
            });
          }
        });
        console.log("created inputList: ", this.inputList);
        this.tableItemsChange();
      });
    }
    await this.fetchBasicParamsList();
    await this.fetchWarehouseSelfLiftinPointList();
  }

  beforeUpload(file: any) {
    console.log("beforeUpload:", file);
    // const isFile = (file.type === 'pdf') || (file.type === 'word') || (file.type === 'ppt') || (file.type === 'image/gif');
    // const isLt5M = file.size / 1024 / 1024 < 5;
    //
    // if (!isFile) {
    //   this.$message.error('上传图片只能是 pdf，word，ppt，gif 格式!');
    // }
    // if (!isLt5M) {
    //   this.$message.error('上传图片大小不能超过 5MB!');
    // }
    // return isFile;
  }

  beforeProductImageUpload(file: any) {
    console.log("beforeUpload:", file);
    const isJPG =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/gif";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isJPG) {
      this.$message.error(
        (this as any).$l.getLanguageText("commodityBrand.tips.type") as string
      );
    }
    if (!isLt2M) {
      this.$message.error(
        (this as any).$l.getLanguageText("commodity.pictureSize") as string
      );
    }
    return isJPG && isLt2M;
  }

  async fetchBasicParamsList() {
    let _this = this as any;
    await api.productBasicParam.getAll({ pageSize: 65535 }).then((res) => {
      _this.basicParamsList = res.items ?? [];
    });
  }

  changeSelect(value: any) {
    if (this.selectAll) {
      this.selectAll = false;
      if (value?.indexOf("selectAll") > -1) {
        this.selectedBasicParamIds = value.filter((p: any) => p != "selectAll");
      } else {
        this.selectedBasicParamIds = [];
      }
    } else {
      //   是否点击了‘全选’选项
      if (value?.indexOf("selectAll") > -1) {
        // 有‘全选’选项，则将‘全部’和其他值放置一块
        const optionsValue: any = [];
        this.basicParamsList.forEach((item: any) => {
          console.log(item);
          optionsValue.push(item.id);
        });
        // this.selectedBasicParamIds = ["selectAll", ...optionsValue];
        this.selectedBasicParamIds = [...optionsValue];
        this.selectAll = true;
      } else {
        // 若是勾选选择的长度和提供的选项长度是一样的，则是 ‘全选’
        if (value?.length === this.basicParamsList.length) {
          const optionsValue: any = [];
          this.basicParamsList.forEach((item: any) => {
            optionsValue.push(item.id);
          });
          // this.selectedBasicParamIds = ["selectAll", ...optionsValue];
          this.selectedBasicParamIds = [...optionsValue];
          this.selectAll = true;
        } else {
          //   都是单选
          this.selectedBasicParamIds = value;
        }
      }
    }
    // 真实的勾选值
    const realSelect =
      this.selectedBasicParamIds?.filter((item: any) => item != "selectAll") ??
      [];
    console.log("realSelect", realSelect);
    console.log("selectedSpecificationIds", this.selectedBasicParamIds);
    // if(!this.form.basicParamList || this.form.basicParamList?.length <= 0){
    this.init(realSelect);
    // }
  }

  init(realSelect: any) {
    let inputList = [] as any[];
    inputList = inputList.concat(this.inputList);
    this.inputList = [];
    for (let i of realSelect) {
      for (let j in this.basicParamsList) {
        let index = inputList.map((item) => item.id).indexOf(i);
        if (
          i === this.basicParamsList[j].id &&
          this.basicParamsList[j].inputType === "DropDownOptions"
        ) {
          let inputArr = JSON.parse(this.basicParamsList[j].inputList!);
          this.inputList.push({
            id: i,
            fullName: this.basicParamsList[j].fullName,
            name: this.basicParamsList[j].name,
            nameFr: this.basicParamsList[j].nameFr,
            inputType: "DropDownOptions",
            parameterFullValues: inputArr.map(
              (item: any) =>
                item.parameterValue +
                (item.parameterValueFr ? `(${item.parameterValueFr})` : "")
            ),
            parameterValues: inputArr.map((item: any) => item.parameterValue),
            parameterValueFrs: inputArr.map(
              (item: any) => item.parameterValueFr
            ),
            selectedFullValues: inputList[index]?.selectedFullValues
              ? inputList[index]?.selectedFullValues
              : [],
            selectedValues: inputList[index]?.selectedValues
              ? inputList[index]?.selectedValues
              : [],
            selectedValueFrs: inputList[index]?.selectedValueFrs
              ? inputList[index]?.selectedValueFrs
              : [],
          });
        } else if (
          i === this.basicParamsList[j].id &&
          this.basicParamsList[j].inputType === "ManualInput"
        ) {
          this.inputList.push({
            id: i,
            fullName: this.basicParamsList[j].fullName,
            name: this.basicParamsList[j].name,
            nameFr: this.basicParamsList[j].nameFr,
            inputType: "ManualInput",
            selectedFullValues: inputList[index]?.selectedFullValues
              ? inputList[index]?.selectedFullValues
              : [],
            selectedValues: inputList[index]?.selectedValues
              ? inputList[index]?.selectedValues
              : [],
            selectedValueFrs: inputList[index]?.selectedValueFrs
              ? inputList[index]?.selectedValueFrs
              : [],
          });
          this.$set(this.manualInputs, this.inputList.length - 1, {
            inputValue:
              inputList[index]?.selectedValues &&
              inputList[index]?.selectedValues.length > 0
                ? inputList[index]?.selectedValues[0]
                : "",
            inputValueFr:
              inputList[index]?.selectedValueFrs &&
              inputList[index]?.selectedValueFrs.length > 0
                ? inputList[index]?.selectedValueFrs[0]
                : "",
          });
        }
      }
    }
    console.log("inputList: ", this.inputList);
  }

  handleInputConfirm(item: any, index: number) {
    if (
      this.manualInputs[index].inputValue &&
      this.manualInputs[index].inputValueFr
    ) {
      let inputValue =
        this.manualInputs[index].inputValue +
        `(${this.manualInputs[index].inputValueFr})`;
      item.selectedFullValues = [inputValue];
      item.selectedValues = [this.manualInputs[index].inputValue];
      item.selectedValueFrs = [this.manualInputs[index].inputValueFr];
      this.tableItemsChange();
    }
  }

  handleCheckboxChange(item: any) {
    if (item.selectedFullValues && item.selectedFullValues.length > 0) {
      let selectedValues = [] as string[];
      let selectedValueFrs = [] as string[];
      item.selectedFullValues.forEach((fullValue: string) => {
        let index = item.parameterFullValues.indexOf(fullValue);
        let value = item.parameterValues[index];
        let valueFr = item.parameterValueFrs[index];
        selectedValues.push(value);
        selectedValueFrs.push(valueFr);
      });
      item.selectedValues = selectedValues;
      item.selectedValueFrs = selectedValueFrs;
    } else {
      item.selectedValues = [];
      item.selectedValueFrs = [];
    }
    this.tableItemsChange();
  }

  tableItemsChange() {
    this.form.basicParamList = this.inputList.map((item) => {
      return {
        id: item.id,
        value:
          item.selectedValues instanceof Array
            ? item.selectedValues.join(",")
            : item.selectedValues,
        valueFr:
          item.selectedValueFrs instanceof Array
            ? item.selectedValueFrs.join(",")
            : item.selectedValueFrs,
      };
    });
  }

  deleteItem(index: any, item: any) {
    let i = this.inputList.map((m) => m.id).indexOf(item.id);
    this.inputList.splice(i, 1);
    let m = this.selectedBasicParamIds.indexOf(item.id);
    this.selectedBasicParamIds.splice(m, 1);
    this.changeSelect(this.selectedBasicParamIds);
    this.tableItemsChange();
  }

  async fetchWarehouseSelfLiftinPointList() {
    await api.warehouseSelfLiftinPoint
      .getAll({ pageSize: 65535 })
      .then((res) => {
        this.warehouseSelfLiftinPointList = res.items ?? [];
      });
  }

  getAddress(item: WarehouseSelfLiftinPointDto) {
    return (
      (item.fkProvince ? item.fkProvince.provinceName! : "") +
      (item.fkCity ? item.fkCity.cityName! : "") +
      item.address
    );
  }

  async save() {
    let _this = this as any;
    this.form.instructionsPath =
      this.form.instructionsPath instanceof Array
        ? this.form.instructionsPath[0]
        : this.form.instructionsPath
        ? this.form.instructionsPath
        : "";
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        console.log(_this.form);

        let isValid = true;
        this.form.skuList = this.form.skuList.filter(
          (item: any) => item.isActive
        );
        this.form.skuList.some((item: any) => {
          if (!item.model) {
            isValid = false;
            this.$message.error(
              (this as any).$l.getLanguageText("commodity.tips.model")
            );
            return true;
          }
          if (!item.unitId) {
            isValid = false;
            this.$message.error(
              (this as any).$l.getLanguageText("commodity.tips.unit")
            );
            return true;
          }
        });
        if (!isValid) {
          return false;
        }

        if (this.form.id) {
          await api.product.update({ body: { ...this.form } });
          this.$message.success(
            (this as any).$l.getLanguageText("basic.updateSuccess") as string
          );
          this.$router.back();
        } else {
          await api.product.create({ body: { ...this.form } });
          this.$message.success(
            (this as any).$l.getLanguageText("basic.createSuccess") as string
          );
          this.$router.back();
        }
      }
    });
  }
}
