























































































































































































import api from "@/api/index"; //ABP API接口
import {Vue, Component, Watch, Ref, Prop} from "vue-property-decorator";
import {
  AttachmentHostType,
  ProductBrandCreateOrUpdateDto,
  ProductBrandDto,
  ProductCategoryCreateOrUpdateDto,
  ProductCategoryDto
} from "../../../../api/appService";
import {createNgTree, INgNode} from "../../../../utils/tree";

@Component({
  name: "classification",
})

export default  class classification extends Vue {
  // @Prop({ required: true })
  // dataId = 0;

  @Prop({ required: true })
  form: any;

  categoryList = [] as ProductCategoryDto[]
  categoryTree: INgNode<ProductCategoryDto>[] = []
  classificationList = [] as ProductCategoryDto[]
  classificationTree: INgNode<ProductCategoryDto>[] = []
  brandList = [] as ProductBrandDto[]

  classificationForm: ProductCategoryCreateOrUpdateDto = {
    id: 0,
    parentId: 0,
    name: "",
    nameFr: "",
    description: "",
    displayOrder: undefined,
  };
  isShow = false;
  brandForm: ProductBrandCreateOrUpdateDto = {
    id: 0,
    name: undefined,
    nameFr: undefined,
    description: "",
    imagePath: "",
    displayOrder: undefined,
  };
  imageUrl = "";
  showDelete = false;
  isShowBrandCreateDialog = false;
  public onProgress = false;
  public uploadPercent = 0;

  @Watch("imageUrl")
  onValueChange(newVal: string | number) {
    if(newVal !== ''){
      this.showDelete = true;
    } else {
      this.showDelete = false;
    }
  }

  async created() {
    // this.dataId = Number((this as any).$route.params.id);
    // if(this.$route.query.categoryId){
    //   this.form.categoryId = Number((this as any).$route.query.categoryId);
    // }
    // if(this.dataId > 0) {
    //   await api.product.get({ id: this.dataId }).then((res) => {
    //     this.form = res;
    //   });
    // }
    await this.fetchClassificationList()
    await this.fetchBrandList()
  }

  //获取全部商品类别
  async fetchClassificationList() {
    let _this = this as any
    await api.productCategory.getAll({
      skipCount: 0,
      maxResultCount: 65535
    }).then(res => {
      this.categoryList = res.items ?? []
      this.classificationList = res.items?.filter((item:ProductCategoryDto) => item.level !== 3) ?? [];
      this.categoryTree = createNgTree(
        res.items ?? [],
        "parentId",
        "id",
        null,
        "children",
        null,
        false,
        "id",
        "fullName"
      );
      console.log(this.categoryTree)
      let defaultData = [{
        children: undefined,
        id: 0,
        key: "",
        label: (this as any).$l.getLanguageText('commodityBrand.primaryClassification') as string,
        parentNode: undefined,
        isLeaf: true,
        hidden: false,
        expanded: false,
        disabled: false,
        data:{
          id: 0,
          fullName: (this as any).$l.getLanguageText('commodityBrand.primaryClassification') as string
        }
      }] as INgNode<ProductCategoryDto>[]
      this.classificationTree = defaultData.concat(createNgTree(
        res.items?.filter((item:ProductCategoryDto) => item.level !== 3) ?? [],
        "parentId",
        "id",
        null,
        "children",
        null,
        false,
        "id",
        "fullName"
      ));
      console.log(this.classificationTree)
    })
  }

  //获取商品品牌
  async fetchBrandList() {
    await api.productBrand.getAll({
      pageSize:65535
    }).then(res => {
      this.brandList = res.items ?? []
    })
  }

  handleCategoriesChange(value: any) {
    this.form!.categoryId = value![value.length - 1];
  }

  handleCreateClassification() {
    // this.$router.push({
    //   name: "BrandCreate"
    // });
    this.classificationForm = {} as ProductCategoryCreateOrUpdateDto;
    this.classificationForm.parentId = 0;
    this.isShow = true
  }

  handleCreateBrand() {
    this.brandForm = {} as ProductBrandCreateOrUpdateDto;
    this.imageUrl = ''
    this.isShowBrandCreateDialog = true
  }

  cascaderClick(value: any) {
    this.classificationForm.parentId = value.id;
    let _this = this as any
    _this.$refs.cascader.checkedValue = value.id;
    _this.$refs.cascader.computePresentText();
    _this.$refs.cascader.toggleDropDownVisible(false);
  }

  async save() {
    (this.$refs.classificationForm as any).validate(async (valid: boolean) => {
      if (valid) {
        console.log(this.classificationForm)
        if(this.classificationForm.parentId === 0){
          this.classificationForm.parentId = undefined
        }
        await api.productCategory.create({body: {...this.classificationForm}})
        this.isShow = false;
        this.$message.success((this as any).$l.getLanguageText('commodityClassification.createSuccessfully') as string);
        await this.fetchClassificationList()
      }
    });
  }

  beforeUpload(file: any) {
    console.log("beforeUpload:", file);
    const isJPG = (file.type === 'image/jpeg') || (file.type === 'image/png') || (file.type === 'image/jpg') || (file.type === 'image/gif');
    const isLt1M = file.size / 1024 / 1024 < 1;

    if (!isJPG) {
      this.$message.error((this as any).$l.getLanguageText('commodityBrand.tips.type') as string);
    }
    if (!isLt1M) {
      this.$message.error((this as any).$l.getLanguageText('commodityBrand.tips.size') as string);
    }
    return isJPG && isLt1M;
  }

  public get uploadData(): any {
    return { hostType: AttachmentHostType.ProductBrand };
  }

  public handleProgress(e: any): void {
    this.onProgress = true;
    this.uploadPercent = e.percent;
    console.log("handleProgress", e);
  }

  get headers() {
    return {
      "Abp-OrganizationUnitId": (this as any).$store.getters["user/ouId"] || "",
      "Abp-TenantId": (this as any).$store.getters["app/tenantId"],
      Authorization: `Bearer ${(this as any).$store.getters["user/token"]}`,
      ".AspNetCore.Culture": "c=zh-Hans|uic=zh-Hans",
    };
  }

  handleRemove(file: any, fileList: any) {
    console.log(file, fileList);
    this.imageUrl = "";
  }

  handleSuccess(res: any, file: any) {
    this.onProgress = false;
    this.uploadPercent = 100;
    this.imageUrl = res.result.url as string
    // this.form.imagePath = res.result.url
  }

  async saveBrand() {
    (this.$refs.brandForm as any).validate(async (valid: boolean) => {
      if (valid) {
        console.log(this.brandForm)
        this.brandForm.imagePath = this.imageUrl
        await api.productBrand.create({body: {...this.brandForm}})
        this.isShowBrandCreateDialog = false;
        this.$message.success((this as any).$l.getLanguageText('commodityBrand.createSuccessfully') as string);
        await this.fetchBrandList()
      }
    });
  }

  dataFormRule = {
    name: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("commodityClassification.tips.name") as string,
        trigger: "blur",
      },
    ],
    nameFr: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("commodityClassification.tips.nameFr") as string,
        trigger: "blur",
      },
    ],
    parentId: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("commodityClassification.tips.classification") as string,
        trigger: "blur",
      }
    ]
  };

  brandFormRule = {
    name: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("commodityBrand.tips.name") as string,
        trigger: "blur",
      },
    ],
    nameFr: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("commodityBrand.tips.nameFr") as string,
        trigger: "blur",
      },
    ]
  };
}
