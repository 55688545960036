


















import {Component, Model, Prop, Vue} from "vue-property-decorator";

@Component({name: 'SimpleVideoUpload'})
export default class SimpleVideoUpload extends Vue {

  @Prop({required: false})
  uploadText?: string

  @Model('change')
  videoUrl!: string;

  get uploadUrl() {
    return (this as any).$uploadUrl;
  }

  private handleBeforeUpload(){
    this.$message.info('正在上传中...');
  }

  private handleAvatarSuccess(res: any) {
    this.$emit('change', res.result.url);
  }
}
